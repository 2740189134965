import React from 'react';
import { IDocument } from '@/types/strapi/documents';

import DocumentCard from '../DocumentCard';

import './index.css';

interface IProps {
    data: Array<IDocument>
}

const DocumentsList = ({ data }: IProps) => {
    return (
        <ul className="document-list">
            { data.map((document, index) => <DocumentCard key={index} document={document} />) }
        </ul>
    );
};

export default DocumentsList;
